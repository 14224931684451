import React, { useCallback, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    setCompany,
    setCompanyChannel,
    setNavigationMenu,
} from "redux/features/user";
import { setPagerState } from "redux/features/customer";
import {
    setPagerState as setPagerStateOrders,
    setPagerStateOrderPending,
    setPagerStateOrderProcessing,
} from "redux/features/order";
import {
    setPagerState as setPagerStateDownload,
    setPagerStateMarketing as setPagerStateDownloadMarketing,
} from "redux/features/download";
import {
    indexWebCustomer,
    setCompanyRequest,
} from "redux/features/indexWeb/actions";
import {
    getDocAdministrative,
    getDocMarketing,
    getDocMarketingTypes,
} from "redux/features/download/actions";
import {
    setCustomerRequest,
    deactivateOrder,
    unSetCustomerRequest,
} from "redux/features/user/actions";
import { getOrders, getOrdersPending, getOrdersProcessing } from "redux/features/order/actions";
import { setPagerState as setPagerStateCatalogue } from "redux/features/catalogue";
import { getFragrancesFilters } from "redux/features/catalogue/actions";
import {
    getLastDocuments,
    getLastOrders,
    getCarousel,
} from "redux/features/home/actions";
import { setKeyword } from "redux/features/search";
import { toast } from "react-toastify";
import { Animation } from "@progress/kendo-react-animation";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { Skeleton } from "@progress/kendo-react-indicators";
import {
    dispatchBroadcastMessageChangeCompany,
    useGetMatchesRoutes,
} from "utils";
import { groupBy, sortBy } from "lodash";
import Loader from "components/loader";
import CompanySelected from "../company";
import "./customers-menu.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
    const [toLanguageString] = useCustomLocalization();
    return {
        noCustomerSelected: toLanguageString(
            "metislab.frontend.components.header.components.customersMenu.none",
            defaultMessages.metislab.frontend.components.header.components
                .customersMenu.none
        ),
        searchCustomer: toLanguageString(
            "metislab.frontend.components.header.components.customersMenu.search",
            defaultMessages.metislab.frontend.components.header.components
                .customersMenu.search
        ),
        resetCustomer: toLanguageString(
            "metislab.frontend.components.header.components.customersMenu.disable",
            defaultMessages.metislab.frontend.components.header.components
                .customersMenu.disable
        ),
    };
};

const CustomersMenu = () => {
    const INITIAL_VALUES_MANAGER = {
        area_manager_code: null,
        area_manager_description: "Area Manager",
    };

    const { noCustomerSelected, searchCustomer, resetCustomer } =
        useLocalizedMessages();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    /**
     * user states
     */
    const company = useSelector((state) => state.user.companyChannel);
    const perimssions = useSelector((state) => state.user.permissions);
    const companies = useSelector((state) => state.user.companies);
    const selectedCustomer = useSelector((state) => state.user.selectedCustomer);
    const selectedOrder = useSelector((state) => state.user.selectedOrder);
    const loadingUserRequest = useSelector((state) => state.user.loading);
    const user = useSelector((state) => state.user.user);

    /**
     * indexWebCustomer states
     */
    const agents = useSelector((state) => state.indexWebCustomer.agents);
    const areaManager = useSelector(
        (state) => state.indexWebCustomer.areaManager
    );
    const indexCustomers = useSelector(
        (state) => state.indexWebCustomer.indexCustomers
    );
    const indexCompany = useSelector(
        (state) => state.indexWebCustomer.selectedCompany
    );
    const loading = useSelector((state) => state.indexWebCustomer.loading);
    const loadingSetCompany = useSelector(
        (state) => state.indexWebCustomer.loadingCompany
    );

    const loadingCompany = useSelector((state) => state.user.loadingCompany);

    /**
     * order states
     */
    const pagerState = useSelector((state) => state.order.pagerState);
    const pagerStateOrderPending = useSelector(
        (state) => state.order.pagerStateOrderPending
    );
    const pagerStateOrderProcessing = useSelector(
        (state) => state.order.pagerStateOrderProcessing
    );

    /**
     * download states
     */
    const pagerStateDownloadAdministrative = useSelector(
        (state) => state.download.pagerState
    );
    const pagerStateDownloadAMarketing = useSelector(
        ( state) => state.download.pagerStateMarketing
    );

    /**
     * searchProduct states
     */
    const keyword = useSelector((state) => state.searchProduct.keyword);

    /**
     * Routes Matches
     */
    const routesCatalogue = [{ path: "/catalogue" }];
    const matchesCatalogue = useGetMatchesRoutes(routesCatalogue);
    const isCataloguePage = matchesCatalogue ? true : false;

    const routesCatalogueProduct = [{ path: "/catalogue/:id" }];
    const matchesCatalogueProduct = useGetMatchesRoutes(routesCatalogueProduct);
    const isCataloguePageProduct = matchesCatalogueProduct ? true : false;

    const routesOrder = [{ path: "/orders/:id" }, { path: "/orders/:id/edit" }];
    const matches = useGetMatchesRoutes(routesOrder);
    const isCatalogueOrderPages = matches ? true : false;

    const routesCustomerDetails = [{ path: "/customers/:id" }];
    const matchesCustomerDetails = useGetMatchesRoutes(routesCustomerDetails);
    const isCustomerDetailsPage = matchesCustomerDetails ? true : false;

    const routesOrderListing = [{ path: "/orders" }];
    const matchesOrdersListing = useGetMatchesRoutes(routesOrderListing);
    const isOrdersListingPage = matchesOrdersListing ? true : false;

    const routesOrderPending = [
        { path: "/orders/pending" },
        { path: "/orders/pending/:id" },
        { path: "/orders/pending/:id/edit" },
    ];
    const matchesOrdersPending = useGetMatchesRoutes(routesOrderPending);
    const isOrdersPendingPage = matchesOrdersPending ? true : false;

    const routesOrderProcessing = [
        { path: "/orders/processing" },
        { path: "/orders/processing/:id" },
    ];
    const matchesOrdersProcessing = useGetMatchesRoutes(routesOrderProcessing);
    const isOrdersProcessingPage = matchesOrdersProcessing ? true : false;

    const routesDownload = [{ path: "/download" }];
    const matchesDownload = useGetMatchesRoutes(routesDownload);
    const isDownloadPage = matchesDownload ? true : false;

    const routesDocsMarketing = [{ path: "/marketing" }];
    const matchesDocsMarketing = useGetMatchesRoutes(routesDocsMarketing);
    const isDocsMarketingPage = matchesDocsMarketing ? true : false;

    const routesDashboard = [{ path: "/dashboard" }];
    const matchesDashboard = useGetMatchesRoutes(routesDashboard);
    const isDashboardPage = matchesDashboard ? true : false;

    const routesPrintOrder = [{ path: "/print/:id" }];
    const matchesPrintOrder = useGetMatchesRoutes(routesPrintOrder);
    const isPrintOrderPage = matchesPrintOrder ? true : false;

    /**
     * isFirstRender is a ref's hook
     * used to run once the call to the endpoints
     */
    const isFirstRender = useRef(true);

    /**
     * refMenu is a ref's hook used to get a DOM
     * access on the element
     */
    const refMenu = useRef();

    const [open, setOpen] = useState(false);

    const [currentAreaManager, setCurrentAreaManager] = useState(null);

    /**
     * State to store the string to search the customers
     */
    const [customerToSearch, setCustomerToSearch] = useState(null);

    /**
     * Temporary list created when area manager and/or client's filter change
     */
    const [listFiltered, setListFiltered] = useState(null);

    const icon = `k-icon k-i-chevron-${open ? "up" : "down"}`;

    useEffect(() => {
        const url = new URL(window.location.href);
        const parameterMap = new Map(url.searchParams);

        const companyChannelInUrl = parameterMap.get("companyChannel");
        if (
            companyChannelInUrl !== undefined &&
            company?.key !== companyChannelInUrl
        ) {
            const newCompany = companies?.find(
                (comp) => comp?.key === companyChannelInUrl
            );
            const args = {
                key: newCompany,
                value: newCompany,
            };
            handleCompany(args);
        } else {
            if (isFirstRender.current && company && !indexCompany && !isPrintOrderPage) {
                dispatch(setCompanyRequest({ toast, company })).then((res) => {
                    const argsCompany = {
                        key: res?.payload?.selectedCompany,
                        value: res?.payload?.selectedCompany,
                    };
                    dispatch(setCompany(argsCompany));
                    dispatch(indexWebCustomer({ toast }));
                    dispatch(setNavigationMenu(res?.payload?.menu));
                });
            }
        }

        isFirstRender.current = false;

        // eslint-disable-next-line
    }, [company, companies, dispatch, indexCompany]);

    /**
     * handleClickOutside is a useCallback hook to close
     * the modal when an user clicks outside it
     */
    const handleClickOutside = useCallback(
        (e) => {
            if (open && !refMenu.current.contains(e.target)) {
                setOpen((prevState) => !prevState);
            }
        },
        [open]
    );

    useEffect(() => {
        open && document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [open, handleClickOutside]);

    /**
     * customersFilter is a react callback's hook used
     * to perform and optimize the operations on the client side
     */
    const customersFilter = useCallback(() => {
        if (currentAreaManager?.area_manager_code || customerToSearch) {
            const areaManagerCode = currentAreaManager?.area_manager_code;
            let filtered = [];
            if (areaManagerCode) {
                filtered = indexCustomers.filter((customer) => {
                    const code_manager = customer.area_manager_code.toLowerCase();
                    return code_manager === areaManagerCode.toLowerCase();
                });
            }
            if (customerToSearch) {
                const list = areaManagerCode ? filtered : indexCustomers;
                filtered = list.filter((customer) => {
                    const code_customer = customer.search_code.toLowerCase();
                    const description_customer =
                        customer.customer_description.toLowerCase();
                    return (
                        code_customer
                            .toLowerCase()
                            .indexOf(customerToSearch.toLowerCase()) !== -1 ||
                        description_customer
                            .toLowerCase()
                            .indexOf(customerToSearch.toLowerCase()) !== -1
                    );
                });
            }

            const filteredList = groupBy(
                sortBy(
                    filtered.map(
                        ({
                             agent_description,
                             search_code,
                             customer_description,
                             IdSfa,
                         }) => {
                            return {
                                agent_description,
                                search_code,
                                customer_description,
                                IdSfa,
                            };
                        }
                    ),
                    ["agent_description"]
                ),
                "agent_description"
            );
            setListFiltered(Object.entries(filteredList));
        } else {
            setListFiltered(null);
        }
    }, [currentAreaManager, customerToSearch, indexCustomers]);

    /**
     * This useEffect is used to optimize customersFilter
     */
    useEffect(() => {
        customersFilter();
    }, [customersFilter]);

    /**
     * This function handles the open state
     * of the customers filter in header
     */
    const handleIndexWebCustomerFilter = () => {
        user?.type !== "1" && setOpen((prevState) => !prevState);
    };

    /**
     * This function dispatch the company selected
     */
    const handleCompany = (e) => {
        resetField();
        if (selectedOrder) {
            const args = {
                navigate,
                toast,
                notification: false,
                redirect: false,
            };
            dispatch(deactivateOrder(args)).then(() => {
                dispatch(unSetCustomerRequest({ toast })).then(() => {
                    dispatch(setCompanyChannel(e.value));
                    dispatch(setCompanyRequest({ toast, company: e.value }))
                        .then((res) => {
                            const argsCompany = {
                                key: res?.payload?.selectedCompany,
                                value: res?.payload?.selectedCompany,
                            };
                            dispatch(setCompany(argsCompany));
                            dispatch(indexWebCustomer({ toast }));
                            dispatch(setNavigationMenu(res?.payload?.menu));

                            //Reset pageState of the list of the customers
                            dispatch(
                                setPagerState({
                                    page: null,
                                    perPage: null,
                                    orderBy: null,
                                    orderByDirection: null,
                                    search: null,
                                    searchBy: null,
                                })
                            );

                            //Reset pageState in listing orders
                            dispatch(
                                setPagerStateOrders({
                                    page: null,
                                    perPage: null,
                                    orderBy: null,
                                    orderByDirection: null,
                                    filterFields: [],
                                    search: null,
                                    searchBy: null,
                                })
                            );

                            //Reset pageState in listing pending's orders
                            dispatch(
                                setPagerStateOrderPending({
                                    page: null,
                                    perPage: null,
                                    orderBy: null,
                                    orderByDirection: null,
                                    search: null,
                                    searchBy: null,
                                })
                            );

                            //Reset pageState in listing processing's orders
                            dispatch(
                                setPagerStateOrderProcessing({
                                    page: null,
                                    perPage: null,
                                    orderBy: null,
                                    orderByDirection: null,
                                    search: null,
                                    searchBy: null,
                                })
                            );

                            //Reset pageState in list of download administrative
                            dispatch(
                                setPagerStateDownload({
                                    page: null,
                                    perPage: null,
                                    orderBy: null,
                                    orderByDirection: null,
                                    filterFields: [],
                                    search: null,
                                })
                            );

                            //Reset pageState in list of download marketing
                            dispatch(
                                setPagerStateDownloadMarketing({
                                    search: null,
                                })
                            );
                        })
                        .then(() => dispatchBroadcastMessageChangeCompany())
                        .then(()=> window.location.reload());
                });
            });
        } else {
            if (selectedCustomer?.code) {
                dispatch(unSetCustomerRequest({ toast })).then(() => {
                    dispatch(setCompanyChannel(e.value));
                    dispatch(setCompanyRequest({ toast, company: e.value }))
                        .then((res) => {
                            const argsCompany = {
                                key: res?.payload?.selectedCompany,
                                value: res?.payload?.selectedCompany,
                            };
                            dispatch(setCompany(argsCompany));
                            dispatch(indexWebCustomer({ toast }));
                            dispatch(setNavigationMenu(res?.payload?.menu));

                            //Reset pageState
                            dispatch(
                                setPagerState({
                                    page: null,
                                    perPage: null,
                                    orderBy: null,
                                    orderByDirection: null,
                                    search: null,
                                    searchBy: null,
                                })
                            );

                            //Reset pageState in listing orders
                            dispatch(
                                setPagerStateOrders({
                                    page: null,
                                    perPage: null,
                                    orderBy: null,
                                    orderByDirection: null,
                                    filterFields: [],
                                    search: null,
                                    searchBy: null,
                                })
                            );

                            //Reset pageState in listing pending's orders
                            dispatch(
                                setPagerStateOrderPending({
                                    page: null,
                                    perPage: null,
                                    orderBy: null,
                                    orderByDirection: null,
                                    search: null,
                                })
                            );

                            //Reset pageState in listing processing's orders
                            dispatch(
                                setPagerStateOrderProcessing({
                                    page: null,
                                    perPage: null,
                                    orderBy: null,
                                    orderByDirection: null,
                                    search: null,
                                    searchBy: null,
                                })
                            );

                            //Reset pageState in list of download administrative
                            dispatch(
                                setPagerStateDownload({
                                    page: null,
                                    perPage: null,
                                    orderBy: null,
                                    orderByDirection: null,
                                    filterFields: [],
                                    search: null,
                                })
                            );

                            dispatch(
                                setPagerStateDownloadMarketing({
                                    search: null,
                                })
                            );
                        })
                        .then(() => dispatchBroadcastMessageChangeCompany())
                        .then(()=> window.location.reload());
                });
            } else {
                dispatch(setCompanyChannel(e.value));
                dispatch(setCompanyRequest({ toast, company: e.value }))
                    .then((res) => {
                        const argsCompany = {
                            key: res?.payload?.selectedCompany,
                            value: res?.payload?.selectedCompany,
                        };
                        dispatch(setCompany(argsCompany));
                        dispatch(indexWebCustomer({ toast }));
                        dispatch(setNavigationMenu(res?.payload?.menu));

                        //Reset pageState
                        dispatch(
                            setPagerState({
                                page: null,
                                perPage: null,
                                orderBy: null,
                                orderByDirection: null,
                                search: null,
                                searchBy: null,
                            })
                        );

                        //Reset pageState in listing orders
                        dispatch(
                            setPagerStateOrders({
                                page: null,
                                perPage: null,
                                orderBy: null,
                                orderByDirection: null,
                                filterFields: [],
                                search: null,
                                searchBy: null,
                            })
                        );

                        //Reset pageState in listing pending's orders
                        dispatch(
                            setPagerStateOrderPending({
                                page: null,
                                perPage: null,
                                orderBy: null,
                                orderByDirection: null,
                                search: null,
                            })
                        );

                        //Reset pageState in listing processing's orders
                        dispatch(
                            setPagerStateOrderProcessing({
                                page: null,
                                perPage: null,
                                orderBy: null,
                                orderByDirection: null,
                                search: null,
                            })
                        );

                        //Reset pageState in list of download administrative
                        dispatch(
                            setPagerStateDownload({
                                page: null,
                                perPage: null,
                                orderBy: null,
                                orderByDirection: null,
                                filterFields: [],
                                search: null,
                            })
                        );

                        dispatch(
                            setPagerStateDownloadMarketing({
                                search: null,
                            })
                        );
                    })
                    .then(() => dispatchBroadcastMessageChangeCompany())
                    .then(()=> window.location.reload());
            }
        }
        if (isCataloguePageProduct || isCataloguePage) {
            navigate("/catalogue");
        }
        if (isCatalogueOrderPages) {
            navigate("/orders");
        }
        if (isOrdersPendingPage) {
            navigate("/orders/pending");
        }
        if (isOrdersProcessingPage) {
            navigate("/orders/processing");
        }
        if (isCustomerDetailsPage) {
            navigate("/customers");
        }
    };

    /**
     * This function set the current area manager selected
     */
    const handleAreaManager = (e) => {
        setCurrentAreaManager(e.value);
    };

    /**
     * This function sets the string to search the customers
     */
    const handleSearchCustomer = (e) => {
        setCustomerToSearch(e.value);
    };

    /**
     * This function makes the request that set the customer selected matchesCatalogue JSON.stringify(obj) === '{}'
     * on the server matchesCatalogue
     */
    const handleSelectCustomer = (idsfa) => {
        if (keyword.length > 0) {
            dispatch(setKeyword(""));
        }

        dispatch(setCustomerRequest({ idsfa, toast })).then(() => {
            if (isDownloadPage) {
                const args = {
                    pager: pagerStateDownloadAdministrative,
                    toast,
                };
                dispatch(getDocAdministrative(args));
            }

            if (isDocsMarketingPage) {
                const args = {
                    pager: pagerStateDownloadAMarketing,
                    toast,
                };
                dispatch(getDocMarketing(args));
                dispatch(getDocMarketingTypes({toast}));
            }

            if (isCataloguePage) {
                dispatch(getFragrancesFilters({ toast }));

                //Reset pageState in catalogue
                dispatch(
                    setPagerStateCatalogue({
                        page: null,
                        perPage: null,
                        class_code: null,
                        fragrance_title: null,
                    })
                );
            }

            if (selectedOrder) {
                const args = {
                    navigate,
                    toast,
                    notification: false,
                    redirect: true,
                };
                dispatch(deactivateOrder(args));
            }

            if (isCataloguePageProduct) {
                navigate("/catalogue");
                dispatch(
                    setPagerStateCatalogue({
                        page: null,
                        perPage: null,
                        class_code: null,
                        fragrance_title: null,
                    })
                );
            }

            if (isCatalogueOrderPages) {
                navigate("/orders");
            }
            if (isOrdersPendingPage) {
                navigate("/orders/pending");
            }
            if (isOrdersProcessingPage) {
                navigate("/orders/processing");
            }

            if (isDashboardPage) {
                dispatch(getLastDocuments({ toast }));
                dispatch(getLastOrders({ toast }));
                dispatch(getCarousel({ toast }));
            }

            //Reset pageState in listing orders
            dispatch(
                setPagerStateOrders({
                    page: null,
                    perPage: null,
                    orderBy: null,
                    orderByDirection: null,
                    filterFields: [],
                    search: null,
                    searchBy: null,
                })
            );

            //Reset pageState in listing pending's orders
            dispatch(
                setPagerStateOrderPending({
                    page: null,
                    perPage: null,
                    orderBy: null,
                    orderByDirection: null,
                    search: null,
                    searchBy: null,
                })
            );

            //Reset pageState in listing processing's orders
            dispatch(
                setPagerStateOrderProcessing({
                    page: null,
                    perPage: null,
                    orderBy: null,
                    orderByDirection: null,
                    search: null,
                    searchBy: null,
                })
            );
        });
        resetField();
    };

    /**
     * Utility function to reset the fields when the company change
     */
    const resetField = () => {
        listFiltered && setListFiltered(null);
        currentAreaManager && setCurrentAreaManager(INITIAL_VALUES_MANAGER);
        customerToSearch && setCustomerToSearch(null);
    };

    /**
     * listFilter contains:
     * true) the list filtered if one input is valued
     * false) list of agents - a sorted and grouped default list
     */
    const listFilter = listFiltered ? listFiltered : agents;

    /**
     * customerSelected is used to concatenate code and description
     * of the client selected in header
     * @returns {string} selectCustomerString
     */
    const customerSelected = () => {
        let selectCustomerString = null;

        // Check if an user had selected a new customer
        if (
            selectedCustomer &&
            selectedCustomer?.search_code &&
            selectedCustomer?.description
        ) {
            selectCustomerString = `${selectedCustomer?.search_code} ${selectedCustomer?.description}`;
        }

        return selectCustomerString;
    };

    const resetSelectedCustomer = () => {
        if (keyword.length > 0) {
            dispatch(setKeyword(""));
        }

        dispatch(unSetCustomerRequest({ toast })).then(() => {
            if (isCataloguePageProduct) {
                navigate("/catalogue");
                dispatch(
                    setPagerStateCatalogue({
                        page: null,
                        perPage: null,
                        class_code: null,
                        fragrance_title: null,
                    })
                );
            }

            if (isDashboardPage) {
                dispatch(getLastDocuments({ toast }));
                dispatch(getLastOrders({ toast }));
                dispatch(getCarousel({ toast }));
            }

            if (selectedOrder) {
                const args = {
                    navigate,
                    toast,
                    notification: false,
                    redirect: isCatalogueOrderPages || isOrdersPendingPage ? true : false,
                };
                dispatch(deactivateOrder(args));
            }

            if (isOrdersListingPage) {
                const args = {
                    pager: pagerState,
                    toast,
                };
                dispatch(getOrders(args));
            }

            if (isOrdersPendingPage) {
                const args = {
                    pager: pagerStateOrderPending,
                    toast,
                };
                dispatch(getOrdersPending(args));
            }

            if (isOrdersProcessingPage) {
                const args = {
                    pager: pagerStateOrderProcessing,
                    toast,
                };
                dispatch(getOrdersProcessing(args));
            }

            if (isDownloadPage) {
                const args = {
                    pager: pagerStateDownloadAdministrative,
                    toast,
                };
                dispatch(getDocAdministrative(args));
            }

            if (isDocsMarketingPage) {
                const args = {
                    pager: pagerStateDownloadAMarketing,
                    toast,
                };
                dispatch(getDocMarketing(args));
                dispatch(getDocMarketingTypes({toast}));
            }

            if (isCataloguePage) {
                dispatch(getFragrancesFilters({ toast }));

                //Reset pageState in catalogue
                dispatch(
                    setPagerStateCatalogue({
                        page: null,
                        perPage: null,
                        class_code: null,
                        fragrance_title: null,
                    })
                );
            }

            setCustomerToSearch(null);
            setCurrentAreaManager(INITIAL_VALUES_MANAGER);
        });
    };

    const customerSelectedText = customerSelected();

    return (
        <section className="c-customers-menu" ref={refMenu}>
            <Button
                className="c-customers-menu__button"
                fillMode="link"
                onClick={handleIndexWebCustomerFilter}
                style={{ color: "white", textAlign: "left" }}
                disabled={
                    loadingUserRequest || loading || loadingCompany || loadingSetCompany
                }
            >
                {loadingUserRequest ||
                loading ||
                loadingCompany ||
                loadingSetCompany ? (
                    <Skeleton
                        shape="text"
                        style={{
                            width: 200,
                            display: "inline-block",
                            backgroundColor: "white",
                        }}
                    />
                ) : (
                    <>
                        <CompanySelected />
                        <span className="c-company-selected__client">
              {`${customerSelectedText || noCustomerSelected}`}
            </span>
                        {user?.type !== "1" && <span className={icon}></span>}
                    </>
                )}
            </Button>
            <Animation
                appear={true}
                enter={true}
                exit={true}
                transitionName="slideFilter"
                transitionEnterDuration={300}
                transitionExitDuration={300}
                className="c-customers-menu__menu"
            >
                {open && (
                    <div className="c-customers-menu__content">
                        {companies.length > 1 && (
                            <div className="c-form-field">
                                <label htmlFor="searchByCompany">Company</label>
                                <DropDownList
                                    disabled={
                                        loadingUserRequest ||
                                        loading ||
                                        loadingCompany ||
                                        loadingSetCompany
                                            ? true
                                            : false
                                    }
                                    name="searchByCompany"
                                    data={companies}
                                    textField="value"
                                    dataItemKey="key"
                                    value={company}
                                    onChange={handleCompany}
                                    popupSettings={{
                                        className: "c-dropdown-customers",
                                    }}
                                />
                            </div>
                        )}

                        {loadingUserRequest ||
                        loading ||
                        loadingCompany ||
                        loadingSetCompany ? (
                            <Loader className="c-loader--customers-menu" />
                        ) : (
                            <>
                                {(perimssions.customerView == undefined || perimssions.customerView )  ? (
                                    <div className="l-tablet-flex u-flex-middle u-gap-16">
                                        {areaManager && areaManager?.length > 0 && (
                                            <div className="c-form-field u-flex-1">
                                                <label htmlFor="">Filter customers by</label>
                                                <DropDownList
                                                    className=""
                                                    name="searchByAreaManager"
                                                    data={areaManager.filter(
                                                        (manager) => manager !== undefined
                                                    )}
                                                    textField="area_manager_description"
                                                    dataItemKey="area_manager_code"
                                                    defaultItem={INITIAL_VALUES_MANAGER}
                                                    value={currentAreaManager}
                                                    onChange={handleAreaManager}
                                                    popupSettings={{
                                                        className: "c-dropdown-customers",
                                                    }}
                                                />
                                            </div>
                                        )}
                                        <div className="c-form-field c-form-field--search c-form-field--search-clients  u-flex-1">
                                            <label htmlFor="">{searchCustomer}</label>

                                            <Input
                                                className=""
                                                name="searchByClient"
                                                placeholder={searchCustomer}
                                                value={customerToSearch || ""}
                                                onChange={handleSearchCustomer}
                                            />
                                            <span className="k-icon k-i-search"></span>
                                        </div>
                                    </div>
                                ) : <></>}
                                {listFilter && listFilter?.length > 0 &&  (perimssions.customerView == undefined || perimssions.customerView ) && (
                                    <ul className="c-customers-menu__list">
                                        {listFilter.map((agent, index) => {
                                            return (
                                                <li key={index}>
                                                    {agent[0]}
                                                    {agent[1] && agent[1]?.length > 0 && (
                                                        <ul className="c-customers-menu__sublist">
                                                            {agent[1].map((customer, index) => {
                                                                const {
                                                                    search_code,
                                                                    customer_description,
                                                                    IdSfa,
                                                                } = customer;
                                                                return (
                                                                    <li
                                                                        key={`${IdSfa}-${index}`}
                                                                        className="l-mobile-flex u-flex-between u-flex-start"
                                                                    >
                                                                        {`${search_code} ${customer_description}`}
                                                                        <Button
                                                                            className=""
                                                                            fillMode="outline"
                                                                            size="small"
                                                                            onClick={() =>
                                                                                handleSelectCustomer(IdSfa)
                                                                            }
                                                                        >
                                                                            Select
                                                                        </Button>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    )}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                                {customerSelectedText && (
                                    <footer className="c-customers-menu__footer">
                                        <Button
                                            className=""
                                            icon="reload"
                                            fillMode="link"
                                            onClick={resetSelectedCustomer}
                                            style={{ textDecoration: "none" }}
                                        >
                                            {resetCustomer}
                                        </Button>
                                    </footer>
                                )}
                            </>
                        )}
                    </div>
                )}
            </Animation>
        </section>
    );
};

export default CustomersMenu;
